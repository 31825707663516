import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import useWindowSize from "../hooks/useWindowSize";
import SmartLogo from "./SmartLogo";
import { isMobile } from "react-device-detect";

const Header = ({ location, isFirstView, setIsFirstView }) => {
  const { height } = useWindowSize();
  const [isHover, setIsHover] = useState(false);
  const [splashState, setSplashState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSplashState(false);
    }, 1500);
  }, []);

  const handleClick = () => {
    setSplashState(!splashState);
    setIsFirstView(false);
  };

  useEffect(() => {
    if (location.state !== null) {
      setSplashState(false);
    }
  }, [location.state, setSplashState]);

  useEffect(() => {
    if (!splashState) {
      setTimeout(() => {
        setIsHover(false);
      }, 1000);
    }
  }, [splashState]);

  const navAnimation = {
    initial: {
      height: "100%",
    },
    animate: {
      height: height,
    },
  };
  return (
    <motion.header
      className="fixed top-0 left-0 w-full z-[9] hover:cursor-pointer"
      onHoverStart={() => !isMobile && setIsHover(true)}
      onHoverEnd={() => !isMobile && setIsHover(false)}
      onClick={() => handleClick()}
    >
      <motion.nav
        className="bg-white text-black flex flex-row-reverse justify-between items-end"
        variants={navAnimation}
        initial="animate"
        animate={splashState ? "animate" : "initial"}
        transition={{ type: "tween", duration: 0.8 }}
      >
        <div className={`w-full px-4 sm:px-8 md:px-20 py-3  relative z-[9]`}>
          <SmartLogo isOpen={isMobile ? splashState : isHover} />
        </div>
      </motion.nav>
      <AnimatePresence>
        {!isFirstView && splashState && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-white text-4xl h-[calc(100vh-60px)] overflow-scroll flex flex-col justify-start gap-y-4 absolute top-0 left-0  bg-white"
          >
            <section className="flex flex-col px-4 sm:px-8 md:px-20 xl:w-[50%] gap-y-8 py-8">
              <div className="text-lg md:text-2xl text-justify">
                <p>
                  Luca Tesei is a young italian architect and researcher. <br />{" "}
                  His study focuses on the evolution of the urban historic
                  landscape and the restoration and retrofitting of exhisting
                  buildings. He graduated with honors with a thesis on
                  Industrial Archaeology. He later contributed to the listing of
                  contemporary Italian architecture of the XX Century promoted
                  by the Italian Ministry of Cultural Heritage. In 2021 He
                  obtained a postgraduate master's degree in "Restoration and
                  Cultures of Heritage". Meanwhile, he has collaborated with
                  renowned architecture firms gaining experience on competitions
                  and executive projects focused on the built environment. He is
                  currently involved in the 38° PhD program in "Architecture,
                  City and Landscape" focusing his research on the Adaptive
                  Reuse of Cultural Heritage.
                </p>
              </div>
              <div className="flex flex-col text-2xl font-bold md:text-4xl md:font-normal">
                <a
                  href="mailto:luca.tesei@hotmail.it"
                  className="hover:underline duration-400"
                >
                  &rarr; mail
                </a>
                <span>&rarr; instagram</span>
              </div>
            </section>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.header>
  );
};

export default Header;

const anim = {
  initial: {
    x: "0",
    opacity: 1,
  },
  animate: {
    x: "-100%",
    opacity: 0,
    transition: {
      delay: 1,
    },
  },
};

const capital_a = {
  initial: {
    x: "0",
  },
  animate: {
    x: "105px",
    transition: {
      delay: 1.3,
      type: "tween",
    },
  },
};

const capital_l = {
  initial: {
    x: "0",
  },
  animate: {
    x: "25px",
    transition: {
      delay: 1.3,
      type: "tween",
    },
  },
};
const SplashLogo = ({ state, isHover }) => {
  return (
    <Link
      to="/"
      state={{ splashState: false }}
      className="text-3xl flex flex-row gap-x-2"
    >
      <div className="relative overflow-hidden flex flex-row">
        <motion.div
          variants={capital_a}
          initial="animate"
          animate={!state && !isHover ? "animate" : "initial"}
        >
          A
        </motion.div>

        <motion.div
          className="inline-flex"
          variants={anim}
          initial="animate"
          animate={!state && !isHover ? "animate" : "initial"}
        >
          rchitetto{" "}
        </motion.div>
      </div>
      <div className="relative overflow-hidden flex flex-row">
        <motion.div
          variants={capital_l}
          initial="animate"
          animate={!state && !isHover ? "animate" : "initial"}
        >
          L
        </motion.div>

        <motion.div
          className="inline-flex"
          variants={anim}
          initial="animate"
          animate={!state && !isHover ? "animate" : "initial"}
        >
          uca{" "}
        </motion.div>
      </div>
      <div className="relative overflow-hidden flex flex-row">
        <motion.div>T</motion.div>

        <motion.div
          className="inline-flex"
          variants={anim}
          initial="animate"
          animate={!state && !isHover ? "animate" : "initial"}
        >
          esei{" "}
        </motion.div>
      </div>
    </Link>
  );
};
