import React, { useState, useEffect } from "react";
import { LayoutGroup, motion } from "framer-motion";

const SmartLogo = ({ isOpen }) => {
  return (
    <div className="flex flex-row justify-end text-2xl font-bold md:text-4xl md:font-normal gap-x-1 md:gap-x-2 ">
      <LayoutGroup>
        <motion.span layout>Studio</motion.span>
        <SmartWord capital={"A"} letters={"rchitetto"} state={isOpen} />
        <SmartWord capital={"L"} letters={"uca"} state={isOpen} />
        <SmartWord capital={"T"} letters={"esei"} state={isOpen} />
      </LayoutGroup>
    </div>
  );
};

export default SmartLogo;

function SmartWord({ capital, letters, state }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(state);
  }, [state]);

  return (
    <motion.div layout className="flex flex-row">
      <motion.span layout>{capital}</motion.span>
      {isOpen && <motion.span layout>{letters}</motion.span>}
    </motion.div>
  );
}
