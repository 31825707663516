import React, { useState, useEffect } from "react";
import Header from "./Header";

import { useScroll } from "framer-motion";

const Layout = ({ location, children }) => {
  const [isFirstView, setIsFirstView] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const { scrollYProgress } = useScroll();

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      if (latest > 0) {
        scrollYProgress.prev > latest
          ? setScrollDirection("up")
          : setScrollDirection("down");
        setIsScrolled(true);
      } else {
        setIsScrolled(false);

        setScrollDirection(null);
      }
    });
  }, [scrollYProgress]);

  return (
    <>
      <Header
        location={location}
        isScrolled={isScrolled}
        scrollDirection={scrollDirection}
        isFirstView={isFirstView}
        setIsFirstView={setIsFirstView}
      />
      <main className="mt-[60px]">{children}</main>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
